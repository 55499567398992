/*
   Material Theme Overrides. 
   See: https://material-ui.com/customization/palette/
*/

import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
//import "./custom.css"

let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#006847'
    },
  },
  typography: {
    h1: { fontFamily: ['Helvetica Neue', 'Helvetica', 'Open Sans', 'Arial', 'sans-serif'].join(',') },
    h2: { fontFamily: ['Helvetica Neue', 'Helvetica', 'Open Sans', 'Arial', 'sans-serif'].join(',') },
    h3: { fontFamily: ['Helvetica Neue', 'Helvetica', 'Open Sans', 'Arial', 'sans-serif'].join(',') },
    h4: { fontFamily: ['Helvetica Neue', 'Helvetica', 'Open Sans', 'Arial', 'sans-serif'].join(',') },
    h5: { fontFamily: ['Helvetica Neue', 'Helvetica', 'Open Sans', 'Arial', 'sans-serif'].join(',') },
    h6: { fontFamily: ['Helvetica Neue', 'Helvetica', 'Open Sans', 'Arial', 'sans-serif'].join(',') },
    subtitle2: { color: "rgba(0, 0, 0, 0.6)", fontSize: "1rem", fontWeight: "bold"},
  },
});

theme = responsiveFontSizes(theme);

export default theme
