import React from "react"
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Logo from "./logo-header"

const useStyles = makeStyles(theme  => ({
  root: {
    padding: theme.spacing(2),
    color: 'white',
    backgroundColor: theme.palette.primary.dark,
    flexGrow: 1,
  },
  copyright: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <footer className={classes.root}>

      <Grid container >
        <Grid item xs={12}>
            <div className={classes.copyright}>
              <Logo /><br /><br />
              © {new Date().getFullYear()}, Site by {` `}
              <a href="https://SummitCreative.io" style={{color: 'white'}}>Summit Creative</a>
            </div>
        </Grid>
      </Grid>

  </footer>

)};

export default Footer
